import { Controller } from '@stimulus/core'

import { uniqueId } from 'lodash'

enum EventName {
  AFTER_INSERT = 'nested-form:after-insert',
  AFTER_REMOVE = 'nested-form:after-remove',
}

const DEFAULT_TEMPLATE_INDEX = 'TEMPLATE_INDEX'

class ControllerBase extends Controller {
  readonly insertionPointTarget!: HTMLElement
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['insertionPoint']

  add(event: Event): void {
    event.preventDefault()
    const content = this.insertionPointTarget.dataset.content.replace(
      new RegExp(this.templateIndex, 'g'),
      uniqueId('new_record_')
    )
    this.insertionPointTarget.insertAdjacentHTML('beforebegin', content)
    const inserted = this.insertionPointTarget.previousElementSibling
    if (inserted) {
      inserted.classList.add('dynamic')
      this.emit(EventName.AFTER_INSERT)
    }
  }

  remove(event: Event): void {
    event.preventDefault()
    if (event.target instanceof Element) {
      const item = event.target.closest('.nested-fields') as HTMLElement

      Array.from(
        item.querySelectorAll('input[name], select[name], textarea[name]'),
        (field: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
          if (field.name.indexOf('[id]') >= 0) {
            return
          }

          if (field.name.indexOf('[_destroy]') >= 0) {
            field.value = '1'
          } else {
            field.disabled = true
          }
        }
      )

      item.classList.add('destroyed')
      item.style.display = 'none'

      if (item.classList.contains('dynamic') && item.parentElement) {
        item.parentElement.removeChild(item)
      }
      this.emit(EventName.AFTER_REMOVE)
    }
  }

  private emit(eventName: EventName): void {
    this.element.dispatchEvent(new Event(eventName, { bubbles: true }))
  }

  get templateIndex(): string {
    if (this.data.has('template-index')) {
      return this.data.get('template-index')
    }
    return DEFAULT_TEMPLATE_INDEX
  }
}
