import { Controller } from 'stimulus';

import Vue from 'vue/dist/vue.esm';

import { numberToHumanSize, getFileType } from './utils';

export default class extends Controller {
  static targets = [
    'root',
    'input',
    'cache',
    'preview',
    'remove',
    'button',
  ];

  connect() {
    if (this.hasPreviewTarget) {
      this.vm = new Vue({
        el: this.previewTarget,
        data: {
          file: this.cache,
        },
        computed: {
          filename: function() {
            if (this.file) {
              return this.file.name;
            }
          },
          filesize: function() {
            if (this.file) {
              return numberToHumanSize(this.file.size);
            }
          },
          filetype: function() {
            if (this.file) {
              return getFileType(this.file.type);
            }
          },
          url: function() {
            if (this.file.url) {
              return this.file.url;
            }
            return URL.createObjectURL(this.file);
          }
        },
        methods: {
          dispose: function() {
            this.$destroy();
          },
        },
      });
    }

    this.sync();
  }

  disconnect() {
    if (this.vm) {
      this.vm.dispose();
    }
  }

  sync() {
    if (this.required) {
      this.rootTarget.classList.add(this.state('required'));
    } else {
      this.rootTarget.classList.remove(this.state('required'));
    }

    if (this.removed) {
      this.rootTarget.classList.add(this.state('removed'));
      this.inputTarget.disabled = true;
      Array.from(this.buttonTargets, btn => {
        btn.classList.add('disabled');
      });
    } else {
      this.rootTarget.classList.remove(this.state('removed'));
      this.inputTarget.disabled = false;
      Array.from(this.buttonTargets, btn => {
        btn.classList.remove('disabled');
      });
    }

    if (this.inputTarget.value || this.cache) {
      this.beChanged();
      this.updatePreview();
    } else if (this.present) {
      this.beNotChanged();
      this.updatePreview();
      this.bePresent();
    } else {
      this.beNotChanged();
      this.beNotPresent();
    }
  }

  onChange() {
    if (this.hasCacheTarget) {
      this.cacheTarget.disabled = true;
    }
    this.changed = !!this.inputTarget.value;

    this.sync();
  }

  cancel() {
    if (this.inputTarget.value) {
      this.inputTarget.value = null;
    }

    if (this.hasCacheTarget) {
      this.cacheTarget.disabled = true;
    }
    this.changed = false;

    this.sync();
  }

  bePresent() {
    this.rootTarget.classList.add(this.state('present'));
    this.inputTarget.removeAttribute('required');
  }

  beNotPresent() {
    this.rootTarget.classList.remove(this.state('present'));

    if (this.required) {
      this.inputTarget.setAttribute('required', 'required');
    }
  }

  beChanged() {
    this.rootTarget.classList.add(this.state('changed'));
  }

  beNotChanged() {
    this.rootTarget.classList.remove(this.state('changed'));
  }

  updatePreview() {
    const preview = {};
    if (this.inputTarget.files.length) {
      this.file = this.inputTarget.files[0];
    } else {
      this.file = this.cache;
    }
  }

  state(state) {
    return `attachment-upload--${state}`;
  }

  component(component) {
    return `attachment-upload__${component}`;
  }

  get required() {
    return this.data.has('required');
  }

  get present() {
    return this.data.has('present');
  }

  get changed() {
    return this.data.has('changed');
  }

  set changed(value) {
    if (value) {
      this.data.set('changed', true);
    } else {
      this.data.delete('changed');
    }
  }

  get removed() {
    return this.removeTarget.checked;
  }

  get cache() {
    if (this.data.has('cache')) {
      return JSON.parse(this.data.get('cache'));
    }
  }

  set file(file) {
    if (this.vm) {
      this.vm.file = file;
    }
  }
}
