const getStylesheet = function (): CSSStyleSheet {
  const ss = document.styleSheets[0]
  if (ss) {
    return ss as CSSStyleSheet
  }

  // Create a stylesheet if one doesn't exist
  const el = document.createElement('style')
  document.head.appendChild(el)
  const newss = document.styleSheets[0]
  document.head.removeChild(el)
  return newss as CSSStyleSheet
}

// Test the pseudo-class by trying to style with it
export function supportsPseudo(pseudoClass: string): boolean {
  const ss = getStylesheet()

  try {
    if (!/^:/.test(pseudoClass)) {
      pseudoClass = ':' + pseudoClass
    }
    ss.insertRule('html' + pseudoClass + '{}', 0)
    ss.deleteRule(0)
    return true
  } catch (e) {
    return false
  }
}
