import { Controller } from '@stimulus/core'

import PerfectScrollbar from 'perfect-scrollbar'
import 'intersection-observer'

import { supportsPseudo } from '../utils'

/*
 * paper-dashboard のふるまいを body タグに追加するコントローラー。
 * paper-dashboard.js を置き換える。
 */

class ControllerBase extends Controller {
  // stimulus が自動的に作成する項目の型を設定する
  // workaround for https://github.com/stimulusjs/stimulus/issues/221
  readonly hasBodyClickTarget!: boolean
  readonly bodyClickTargets!: Element[]

  readonly hasSidebarToggleTarget!: boolean
  readonly sidebarToggleTarget!: Element
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['sidebarToggle', 'bodyClick']

  isWindows!: boolean
  supportsWebkitScrollbar!: boolean
  pss!: PerfectScrollbar[]

  ondisconnect: (() => void)[]

  initialize(): void {
    this.isWindows = navigator.platform.indexOf('Win') > -1
    this.supportsWebkitScrollbar = supportsPseudo('::-webkit-scrollbar')

    this.pss = []
    this.ondisconnect = []

    this.closeSidebar = this.closeSidebar.bind(this)
  }

  connect(): void {
    this.initPerfectScrollbar()
    this.initSidebar()

    // turbolinks で移動するときはサイドバーを閉じる
    document.addEventListener('turbolinks:visit', this.closeSidebar)

    // sidebar 開閉ボタンを controller の制御下に入れる
    Array.from(
      this.element.querySelectorAll('.main-panel .navbar .navbar-toggle'),
      (toggle) => {
        toggle.setAttribute(
          'data-target',
          `${this.scope.identifier}.sidebarToggle`
        )
        toggle.setAttribute(
          'data-action',
          `click->${this.scope.identifier}#toggleSidebar`
        )
      }
    )
  }

  disconnect(): void {
    this.ondisconnect.forEach((callback) => callback.call(this))
    this.removePerfectScrollbar()

    document.removeEventListener('turbolinks:visit', this.closeSidebar)
  }

  initPerfectScrollbar(): void {
    if (this.isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      Array.from(
        this.element.querySelectorAll('.sidebar .sidebar-wrapper, .main-panel'),
        (container: HTMLElement) => {
          const ps = new PerfectScrollbar(container)
          this.pss.push(ps)

          const content = container.querySelector('.content')
          if (content) {
            // content のサイズに変更があったとき ps.update を実行する
            const observer = new IntersectionObserver(() => {
              ps.update()
            })

            observer.observe(content)

            this.ondisconnect.push(() => observer.unobserve(content))
          }
        }
      )

      this.element.parentElement.classList.add('perfect-scrollbar-on')

      if (this.supportsWebkitScrollbar) {
        this.element.parentElement.classList.add('webkit-scrollbar-on')
      }
    }
  }

  removePerfectScrollbar(): void {
    this.pss.forEach((ps) => ps.destroy())
    this.pss.length = 0
  }

  initSidebar(): void {
    if (this.sidebarOpen) {
      this.openSidebar()
    }
  }

  toggleSidebar(): void {
    if (this.sidebarOpen) {
      this.closeSidebar()
    } else {
      this.openSidebar(580)
    }
  }

  openSidebar(delay = 0): void {
    if (!this.hasBodyClickTarget) {
      const bodyClick = document.createElement('div')
      bodyClick.setAttribute('id', 'bodyClick')
      bodyClick.setAttribute(
        'data-target',
        `${this.scope.identifier}.bodyClick`
      )
      bodyClick.setAttribute(
        'data-action',
        `click->${this.scope.identifier}#closeSidebar`
      )
      this.element.appendChild(bodyClick)
    }

    this.sidebarOpen = true
    if (delay) {
      setTimeout(() => {
        this.sidebarToggled = true
      }, delay)
    } else {
      this.sidebarToggled = true
    }
  }

  closeSidebar(): void {
    this.sidebarOpen = false
    this.sidebarToggled = false

    if (this.hasBodyClickTarget) {
      Array.from(this.bodyClickTargets, (bodyClick) => {
        bodyClick.parentElement.removeChild(bodyClick)
      })
    }
  }

  get sidebarOpen(): boolean {
    return this.element.parentElement.classList.contains('nav-open')
  }

  set sidebarOpen(value: boolean) {
    if (value) {
      this.element.parentElement.classList.add('nav-open')
    } else {
      this.element.parentElement.classList.remove('nav-open')
    }
  }

  set sidebarToggled(value: boolean) {
    if (this.hasSidebarToggleTarget) {
      if (value) {
        this.sidebarToggleTarget.classList.add('toggled')
      } else {
        this.sidebarToggleTarget.classList.remove('toggled')
      }
    }
  }
}
