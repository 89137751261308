import { Controller } from '@stimulus/core'

import { mapKeys } from 'lodash'

import {
  fireEvent,
  PREFMAP,
  JSONP_PATH,
  ZipData,
  ZipDataDictionary,
} from './postalcode_controller.h'

interface Window {
  AjaxZipP: { zipdata(data: ZipDataDictionary): void }
}
declare const window: Window

const eventName = 'ajaxzipp:zipdata:received'
const state: { [key: string]: 'loading' | 'loaded' } = {}
const cache: ZipDataDictionary = {}

class ControllerBase extends Controller {
  readonly hasPostalcodeTarget!: boolean
  readonly postalcodeTarget!: HTMLInputElement
  readonly addressTargets!: HTMLInputElement[]
}

/*
 * AjaxZipP の置き換え。
 * target=postalcode.postalcode を郵便番号として、
 * target=postalcode.address の内容を自動入力する
 *
 * <div data-controller="postalcode">
 *   <input data-target="postalcode.postalcode" />
 *   <input data-target="postalcode.address" />
 *   <input data-target="postalcode.address" data-address-format="$2" />
 *   <select data-target="postalcode.address" data-address-format="$0">
 *     <option></option>
 *     <option value="32">島根県</option>
 *   </select>
 * </div>
 *
 * controller option
 *  insert-hyphen: 郵便番号にハイフンを自動的に付加する
 *  format: address のフォーマット (default: '$1$2$3$4')
 *    $0: 県コード '32'
 *    $00: 2桁県コード 左側を 0 でパディングする
 *    $1: 県名 '島根県'
 *    $2: 市町村名 '松江市'
 *    $3: 地域等 '北陵町'
 *    $4: 大字等 ''
 *
 * address option:
 *  address-format: controller のオプションと同じ
 */
export default class extends (Controller as typeof ControllerBase) {
  static targets = ['postalcode', 'address']

  onReceive: EventListenerObject

  initialize(): void {
    this.decode = this.decode.bind(this)
    this.onReceive = {
      handleEvent: this.receive.bind(this),
    }

    if (typeof window.AjaxZipP === 'undefined') {
      // zip-xxx.js がコールバックとして AjaxZipP.zipdata を呼ぶので定義する
      window.AjaxZipP = {
        zipdata: (data): void => fireEvent(eventName, data),
      }
    }
  }

  connect(): void {
    document.addEventListener(eventName, this.onReceive, false)

    this.postalcodeTarget.addEventListener('input', this.decode, false)
    this.postalcodeTarget.addEventListener('keyup', this.decode, false)
  }

  disconnect(): void {
    document.removeEventListener(eventName, this.onReceive)
    if (this.hasPostalcodeTarget) {
      this.postalcodeTarget.removeEventListener('input', this.decode)
      this.postalcodeTarget.removeEventListener('keyup', this.decode)
    }
  }

  invalid(): void {
    this.postalcodeTarget.classList.add('is-invalid')
    this.postalcodeTarget.classList.remove('is-valid')
  }

  reset(): void {
    this.postalcodeTarget.classList.remove('is-invalid')
    this.postalcodeTarget.classList.remove('is-valid')
  }

  decode(): void {
    const value = this.postalcodeTarget.value
    if (!value) {
      this.reset()
      return
    }

    // 000-?0000 に前方一致しない場合は invalid
    if (!/^\d{0,3}-?\d{0,4}$/.test(value)) {
      this.invalid()
      return
    }

    // value から 3, 4 桁を取り出す
    let m, zip3, zip4
    if ((m = value.match(/^(\d{3})-?(\d{4}$)?/))) {
      zip3 = m[1]
      zip4 = m[2]
    }

    // 前半 3 桁が見つからないときは reset
    if (!zip3) {
      this.reset()
      return
    }

    // 前半 3 桁が決まった時点でデータのロードを開始する
    if (!state[zip3]) {
      this.reset()
      this.loadData(zip3)
      return
    }

    // 後半の 4 桁がないときは停止
    if (!zip4) {
      return
    }

    // loading or loaded
    if (state[zip3] === 'loaded') {
      const code = zip3 + zip4
      if (cache[code]) {
        // value にハイフンがなければハイフンを付ける
        if (this.insertHyphen && value === code) {
          this.postalcodeTarget.value = `${zip3}-${zip4}`
        }
        this.setData(cache[code])
        this.reset()
      } else {
        // 該当するデータがなければ invalid
        this.invalid()
      }
    } else {
      // データのロード待ち
      setTimeout(this.decode, 100)
    }
  }

  loadData(zip3: string): void {
    // load postal data
    state[zip3] = 'loading'

    // load data with jsonp
    const s = document.createElement('script')
    s.setAttribute(
      'src',
      `${this.jsonpPath}/zip-${zip3}.js?t=${new Date().getTime()}`
    )
    s.setAttribute('charset', 'utf-8')
    s.onload = (): void => {
      state[zip3] = 'loaded'
      this.decode()
    }
    document.body.appendChild(s)
  }

  setData(data: ZipData): void {
    Array.from(this.addressTargets, (addressTarget) => {
      const format = addressTarget.dataset.addressFormat || this.format
      const value = format
        .replace('$00', data[0] < 10 ? '0' + data[0] : '' + data[0])
        .replace('$0', '' + data[0])
        .replace('$1', PREFMAP[data[0]])
        .replace('$2', data[1] || '')
        .replace('$3', data[2] || '')
        .replace('$4', data[3] || '')
      if (addressTarget.value && addressTarget.value.indexOf(value) === 0) {
        // 前半が一致する場合は何もしない
        return
      }
      addressTarget.value = value
      addressTarget.focus()
    })
  }

  receive(evt: CustomEvent): void {
    const data = mapKeys(evt.detail, (v, k) => '' + k)
    Object.assign(cache, data)
  }

  get jsonpPath(): string {
    return this.data.get('jsonpPath') || JSONP_PATH
  }

  get insertHyphen(): boolean {
    return this.data.get('insertHyphen') !== 'false'
  }

  get format(): string {
    return this.data.get('format') || '$1$2$3$4'
  }
}
