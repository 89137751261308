import Turbolinks from 'turbolinks'

Turbolinks.start()

document.addEventListener('ajax:beforeSend', function (
  event: Event & { detail: [XMLHttpRequest, { url: string }] }
) {
  console.debug(event)
  if (event.target instanceof HTMLFormElement) {
    const form = event.target
    if (
      form.method === 'get' &&
      form.dataset &&
      form.dataset.remote === 'true'
    ) {
      Turbolinks.visit(event.detail[1].url)
      event.returnValue = false
      return false
    }
  }
})
