/* ファイル形式検出 */

export function isImage(type) {
  return /^image\//.test(type);
}

export function isVideo(type) {
  return /^video\//.test(type);
}

export function isAudio(type) {
  return /^audio\//.test(type);
}

export function getFileType(type) {
  if (isAudio(type)) {
    return "audio";
  } else if (isVideo(type)) {
    return "video";
  } else if (isImage(type)) {
    return "image";
  }
  return "binary";
}

/* ファイルサイズ計算 */

const UNITS = [
  [1024 * 1024 * 1024, 'ギガバイト'],
  [1024 * 1024, 'メガバイト'],
  [1024, 'キロバイト'],
  [1, 'バイト']
];

export function numberToHumanSize(size) {
  let unit = UNITS[UNITS.length - 1][1];

  for (let i = 0; i < UNITS.length; i++) {
    if (size < UNITS[i][0]) {
      continue;
    }
    size = size * 1.0 / UNITS[i][0];
    unit = UNITS[i][1];
    break;
  }

  return Number(size).toLocaleString( undefined, { maximumFractionDigits: 1 }) + unit;
}
