import { Controller } from '@stimulus/core'
import Sortable from 'sortablejs'

export default class extends Controller {
  private sortable: Sortable

  initialize(): void {
    this.onUpdate = this.onUpdate.bind(this)
  }

  connect(): void {
    const options = this.data.has('options')
      ? JSON.parse(this.data.get('options'))
      : {}

    Object.assign(options, { onUpdate: this.onUpdate })

    if (this.element instanceof HTMLElement) {
      this.sortable = new Sortable(this.element, options)

      // turbolinks でページ遷移するときに破棄する
      document.addEventListener('turbolinks:before-cache', () => {
        this.destroy()
      })
    }
  }

  // sortable がネストしているときに外側をソートすると、そのたび内側は disconnect される。
  // おそらくその副作用で外側の onXXX イベントが発生しなくなるため、disconnect では destroy しない。
  // disconnect() {}

  update(event: Event): void {
    if (event.target === this.element) {
      this.onUpdate()
    }
  }

  onUpdate(): void {
    const ids = this.sortable.toArray()
    ids.forEach((id, idx) => {
      const position = this.element.querySelector(`[data-sortable="${id}"]`)
      if (position instanceof HTMLInputElement) {
        position.value = '' + (idx + 1)
      }
    })
  }

  destroy(): void {
    if (this.sortable) {
      this.sortable.destroy()
      this.sortable = null
    }
  }
}
